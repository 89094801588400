<script>
import LSection from "@/components/layout/LSection.vue";
import LView from "@/components/layout/LView.vue";
import IconCheck from "@/components/icons/Check.vue";
import {PhArrowRight} from "@phosphor-icons/vue";

export default {
  name: "Students",
  components: {
    PhArrowRight,
    IconCheck,
    LView, LSection
  }
}
</script>

<template>
  <div class="students view">
    <LView class="text-xl font-normal text-gray-600">
      <template #body>
        <img src="../../../assets/images/StudentsHeaderImage.png" alt="">
        <LSection class="mt-20">
          <template #body>

            <h1 class="font-header">What is DigiQ?</h1>
            <div class="textblock">
              <div>
                DigiQ (Digitally Enhanced Quantum Technology Master) is Europe’s largest quantum education project. It aims to reshape the future landscape of quantum physics. Its mission is to redefine the trajectory of quantum physics in the years to come and ensure Europe’s leading role in this new technological revolution.
              </div>
              <div>
                One central pillar of the European agenda is to reshape the quantum education landscape by making it more accessible, inclusive, transparent, and applicable. And this is DigiQ.
              </div>
              <div>
                DigiQ stands as Europe’s largest quantum education project, with a mission to redefine the trajectory of quantum physics in the years to come.
              </div>
            </div>
          </template>
        </LSection>
        <LSection>
          <template #body>
            <h1 class="font-header">What does DigiQ offer to students?</h1>
            <div class="textblock">
              <div class="">
                Through DigiQ, you can study innovative quantum <em>courses</em>, participate in <em>internships and exchange programs</em>, and join one of four DigiQ <em>networks</em> with fellow students and researchers from across Europe.
              </div>
              <div>
                DigiQ is a flexible program designed to be accessible to all students. You may study just one course, join a network, or take a single internship.
              </div>
              <div>
                For those who wish to become experts in quantum technology, the <em>DigiQ Master Certification</em> is available for studying <em>30 ECTS of DigiQ courses</em> and completing a year of attendance in a DigiQ network.
              </div>
              <div>
                DigiQ allows students of any kind of Master's degree across Europe to specialize their studies in the field of Quantum Technology. The full DigiQ Master certification may be considered as an addition to any Master's, while it is equally valuable to participate in any number of courses or internships without completing the full 30 ECTS.
              </div>



            </div>
          </template>
        </LSection>
        <LSection>
          <template #body>
            <div class="bg-diversity/10 rounded-2xl px-12 py-6 mb-12">
              <h2>DIGIQ Modules and Courses</h2>
              <p>
                Hands-on educational innovations are being built into many of your future courses, using resources from
                all 24 of the DigiQ partners. We call these “upgraded” courses <em>DigiQ-Courses</em>.
              </p>
              <p>
                You have to study up to <em>30 ECTS</em> of DigiQ courses to attain the <em>DigiQ Master</em>
                certificate.
              </p>
              <p>
                Every DigiQ University has different DigiQ-Courses, including some taught online from across Europe! To
                find out more about what’s available to you, contact your local university representative.
              </p>
              <p>
                In the coming months, this page will be updated with a comprehensive system for acessing courses,
                internships, and networks: DigiQ-Track.
              </p>
            </div>
          </template>
        </LSection>
        <LSection>
          <template #body>
            <div class="bg-communication/10 rounded-2xl px-12 py-6 mb-12">
              <h2>DIGIQ Internships and exchanges</h2>
              <div class="flex">
                <img src="../../../assets/images/internships_digiq.png" width="300px" alt="">
                <p>
                  Fully funded internships and exchanges of up to 6 months are available across Europe and applications
                  will open in October.
                </p>
              </div>
            </div>
          </template>
        </LSection>
        <LSection>
          <template #body>
            <div class="bg-innovation/10 rounded-2xl px-12 py-6 mb-12">
              <h2>DIGIQ Networks</h2>
              <p class="text-right font-semibold my-12">
                Connect with like-minded students and find mentors at top European institutions!
              </p>
              <div class="spacer h-12"></div>
              <div class="flex items-center gap-4 mb-12">
                <div class="w-56 h-full">
                  <span class="font-semibold text-2xl">What we offer</span>
                </div>
                <div class="list flex flex-col gap-4">
                  <div class="flex items-center gap-2">
                    <IconCheck></IconCheck>
                    <div>DigiQ sponsored participation in events and schools</div>
                  </div>
                  <div class="flex items-center gap-2">
                    <IconCheck></IconCheck>
                    <div>Mentored research projects in collaboration with other network members</div>
                  </div>
                  <div class="flex items-center gap-2">
                    <IconCheck></IconCheck>
                    <div>Hybrid and online seminars on hot topics in quantum technologies</div>
                  </div>
                </div>
              </div>

              <div class="flex items-center gap-4 mb-12">
                <div class="w-56 h-full">
                  <span class="font-semibold text-2xl">What you contribute</span>
                </div>
                <div class="list flex flex-col gap-4">
                  <div class="flex items-center gap-2">
                    <IconCheck></IconCheck>
                    <div>Attendance of at least one in-person event</div>
                  </div>
                  <div class="flex items-center gap-2">
                    <IconCheck></IconCheck>
                    <div>Active and regular participation in online and hybrid events and <span
                        title="Details on planned events will be provided soon!">seminars*</span></div>
                  </div>
                </div>
              </div>

              <div class="flex items-center gap-4 mb-12">
                <div class="w-56 h-full">
                  <span class="font-semibold text-2xl">The networks</span>
                </div>
                <div class="list flex flex-col gap-4">
                  <div class="">
                    <div class="flex font-semibold">
                      <div class="w-20">
                        EFEQT
                      </div>
                      <div>
                        Empowering the Future Experts in Quantum Science and Technology
                      </div>
                    </div>
                    <div>
                      Explore the full breadth of quantum technologies from sensing to communication and computing.
                    </div>
                  </div>
                  <div class="">
                    <div class="flex font-semibold">
                      <div class="w-20">
                        AQT
                      </div>
                      <div>
                        Advocates for Quantum Technologies
                      </div>
                    </div>
                    <div>
                      Participate in communicating the enormous impact of the quantum revolution to the public.
                    </div>
                  </div>
                  <div class="">
                    <div class="flex font-semibold">
                      <div class="w-20">
                        AQISS
                      </div>
                      <div>
                        Applied Quantum Information Science Student Network
                      </div>
                    </div>
                    <div>
                      Take a deep dive into the practical use of quantum hardware.
                    </div>
                  </div>
                  <div class="">
                    <div class="flex font-semibold">
                      <div class="w-20">
                        NQC
                      </div>
                      <div>
                        Network for Quantum Computing
                      </div>
                    </div>
                    <div>
                      Explore the inner workings of quantum algorithms.
                    </div>
                  </div>

                </div>
              </div>
              <div class="flex items-center !my-6 pt-8 text-black text-3xl tracking-tight font-black justify-center ">
                <span class="bg-amber-300 px-2 rounded-xl" >Network application 2024/2025 is now open</span> <PhArrowRight :size="24" class="mx-2 " /> <button></button><a class="shadow px-2 rounded-xl bg-white"
                                                                                                                                                                           href="https://docs.google.com/forms/d/1rF5srscrLMwf4AHA8elN9STTNE0nFJW1BAckPY7C1CA/viewform?edit_requested=true">Apply for Networks Here</a>
              </div>
            </div>
          </template>
        </LSection>
        <LSection>
          <template #body>

            <h1 class="font-header">How can I join DigiQ?</h1>
            <p>
              As of now, DigiQ-Network applications are OPEN! See <a href="https://digiq.eu/networks">here for
              detailed information on
              the four networks</a>, and APPLY NOW!
            </p>
            <p>
              In the coming weeks, DigiQ Courses, and Internsips/exchanges will be able to sign up to on DigiQ-Track,
              a
              system available soon on <a href="https://digiq.eu/">DigiQ.eu</a> soon.
            </p>
            <img class="w-full mt-12" src="../../../assets/images/futureofQ.png" alt="">
          </template>
        </LSection>
      </template>
    </LView>
  </div>
</template>

<style scoped>
h1 {
  @apply
  text-5xl font-bold text-gray-800
  my-6
}

h2 {
  @apply
  text-3xl font-bold text-gray-800
  my-4
}

p {
  @apply
  my-4
}

.textblock {
  @apply
  h8:columns-2
  k12:columns-3
  mb-20
}

.textblock div {
  @apply
  mb-4
}

em {
  @apply
  font-black
  tracking-tight
  not-italic
}

a {
  @apply
  text-diversity/80
  hover:text-diversity
  transition-colors
  duration-200
}
</style>