import {_PUT_STATUS, LIST_APP_USERS, LIST_ASSETS, SET_USER_ROLE} from "@/store/operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";
import {getAssetUserActionUrl} from "@/store/lib/apiUrls";

export default {
    [LIST_APP_USERS](
        context,
        {
            appId,
            permissionHostDataType,
            permissionHostRepoId,
            offset,
            limit,
            sorting,
            filter
        }) {
        const options = {
            permissionHostDataType,
            permissionHostRepoId,
            offset,
            limit,
            sorting,
            filter: JSON.stringify(filter)
        }
        return throttleBufferedAction({
            action: axios.post,
            url: getAssetUserActionUrl('App', appId, 'users'),
            params: options
        })
            .then(response => {
                context.commit(
                    LIST_ASSETS,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: LIST_APP_USERS, result: response.data})
                return response
            })
    },
    [SET_USER_ROLE](context, {appId, userRepoId, role}) {
        const options = {
            dataType: 'App',
            assetId: appId,
            role
        }
        return axios.put(
            getAssetUserActionUrl('Account', userRepoId, 'setUserRole'),
            options
        )
            .then(response => {
                context.commit(
                    LIST_ASSETS,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: SET_USER_ROLE, result: response.data})
                return response
            })
    }
}