<template>
  <div class="landing -mt-20">
    <LView :use-margins="false">
      <template #menubar>

        <DigiQMenuBar class="fixed w-full h-20 z-50"></DigiQMenuBar>

      </template>
      <template #body>

        <div class="mt-20 pt-20">
            <router-view></router-view>
        </div>

      </template>
    </LView>
  </div>
</template>

<script>
import {state} from "@/store/store";
import {computed} from "vue";
import DigiQMenuBar from "@/components/DigiQMenuBar.vue";
import LView from "@/components/layout/LView.vue";

export default {
  name: "LandingView",
  components: {
    LView,
    DigiQMenuBar,
  },
  setup() {
    const clientId = computed(() => state.clientId);
    return {
      clientId,
      state,
    };
  },
};
</script>

<style scoped></style>
