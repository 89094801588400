import md5 from './md5'
import {isString} from "@/lib/getVariableType";
import config from '../../../../config'

const {productionDomain} = config

export const getGravatarUrl = (email, {
    size,
    defaultImageSrc = 'robohash'
} = {}) => {
    let src = 'https://gravatar.com/avatar/' + md5(email
        .trim()
        .toLowerCase()
    )
    const params = {}
    const defaultImageSrcIsRelativeUrlInDevelopment = defaultImageSrc &&
        defaultImageSrc.startsWith('/') &&
        location.hostname === 'localhost'
    if (defaultImageSrcIsRelativeUrlInDevelopment) defaultImageSrc = `https://${productionDomain}${defaultImageSrc}`
    params.d = defaultImageSrc
    if (size) {
        if (isString(size)) size = parseInt(size)
        size = Math.min(Math.max(20, size ?? 80), 200)
        params.size = size
    }
    if (Object.keys(params).length) {
        src += '?' +
            Object.entries(params).reduce((params, entry) => {
                params.push(entry.join('='))
                return params
            }, []).join('&')
    }
    return src
}
