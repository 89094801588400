export default [
    {
        path: "/asset/:dataType",
        name: "assets",
        props: true,
        meta: {
            requireDevOp: true
        },
        component: () => import("../../components/asset/Assets.vue"),
    },
    {
        path: "/asset/:dataType/:repoId",
        name: "asset",
        props: true,
        meta: {
            requireDevOp: true
        },
        component: () => import("../../components/asset/Asset.vue"),
    },

]