<script>
import {computed} from "vue";
import {useStore} from "vuex";
import {isArray} from "@/lib/getVariableType";
import Credentials from "@/components/track/CredentialWallet/Credentials.vue";

export default {
  name: "TeacherDetailsCardFields",
  components: {Credentials},
  props: {
    repoId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore()

    const hostingCommitments = computed(() => {
      const docs = []

      Object.values(store.state.assets?.Course ?? {})
          .filter(doc => doc.host === props.repoId)
          .forEach(doc => docs.push(doc))

      Object.values(store.state.assets?.Internship ?? {})
          .filter(doc => doc.host === props.repoId)
          .forEach(doc => docs.push(doc))

      Object.values(store.state.assets?.Networks ?? {})
          .filter(doc => doc.host === props.repoId)
          .forEach(doc => docs.push(doc))

      return docs
    })

    const hostingCredentials = computed(() => {
      const creds = new Set()
      hostingCommitments.value?.forEach(doc => {
        let items = doc.competenceFramework
        if (!isArray(items)) items = [items]
        items.forEach(cred =>
            creds.add(cred)
        )
      })
      return [...creds]
    })

    return {
      hostingCommitments,
      hostingCredentials,
    }
  }
}
</script>

<template>
  <div class="studentDetailsCardFields">
    <section>
      <div
          v-if="hostingCredentials.length"
          class="text-xl font-semibold tracking-tight mb-3"
      >
        Credentials
      </div>
      <Credentials
          class="mt-4"
          :list="hostingCredentials"
          :use-columns="false"
      ></Credentials>
    </section>
  </div>
</template>

<style scoped>

</style>