<script>
import {computed, ref} from "vue";
import {withoutPrefix} from "@/lib/prefixPostfix";

const domainColors = [
  "",
  "#708B12",
  "#005042",
  "#00384F",
  "#006F97",
  "#164194",
  "#510E33",
  "#8E2F88",
  "#790920",
]
export default {
  name: "Credential",
  props: {
    credential: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const credential = ref(props.credential.trim())
    const digits = computed(() => credential.value.split(' ')[0])
    const domainIndex = computed(() => parseInt(digits.value.split('.')[0]))
    const label = computed(() => withoutPrefix(credential.value, digits.value).trim())

    const numberStyle = computed(() => ({
      backgroundColor: domainColors?.[domainIndex.value] ?? 'black',
    }))

    const hoverStyle = computed(() => ({
      styleObject: {
        '--color': 'black',
        '--color-hover': domainColors?.[domainIndex.value] ?? 'black',
      },
      color: domainColors?.[domainIndex.value] ?? 'black',
    }))

    return {
      digits,
      hoverStyle,
      label,
      numberStyle,
    }
  }
}
</script>

<template>
  <div
      class="
        credential
        flex
        py-1 px-2
">
    <div class="flex flex-row gap-4 items-center">
      <div
          class="credential-number bg-black text-white flex justify-center w-7 h-7 aspect-square transform rotate-45 p-[1px]"
          :class="[
                      'credential-number',
                  ]"
          :style="numberStyle"
      >
        <div class=" transform text-sm pt-[2px] -rotate-45">
          {{ digits }}
        </div>
      </div>
      <div class="credential-text"
           :class="{
                      'credential-number': true,
                   }"
           :style="hoverStyle"
      >
        {{ label }}
      </div>

    </div>
  </div>
</template>

<style scoped>

</style>