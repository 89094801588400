import {createRouter, createWebHistory} from "vue-router";
import {state} from "@/store/store.js";
import LandingView from "@/components/views/digiq/DigiQLandingView.vue";
import assetRoutes from "@/router/routes/assetRoutes";
import accountRoutes from "@/router/routes/accountRoutes";
import {capitalize} from "@/lib/typeHelpers";
import config from '../../config.js'
import {appRoutes} from "@/router/routes/appRoutes";

const {appId, basePath, host, permissionThresholds} = config;
const getRoute = (routes, name) =>
        routes.reduce((match, route) => {
            if (!match) {
                if (route.name === name) match = route;
                else if (route.children) match = getRoute(route.children, name);
            }
            return match;
        }, null);

const routes = [
    {
        path: "/",
        name: "home",
        component: LandingView,
    },
    ...appRoutes,
    ...assetRoutes,
    ...accountRoutes,
    { // todo: test
        path: `/:backendFilePath(${appId}/.+)`,
        redirect(to) {
            const {backendFilepath} = to.params
            return {path: `${host}/${backendFilepath}`}
        }
    }
];
const scrollBehavior = (to, from, savedPosition) => {
    if (to.query.maintainScroll) {
        return savedPosition || { x: 0, y: 0 };
    } else {
        // Else, scroll to the top
        return { x: 0, y: 0 };
    }
}

const router = createRouter({
            history: createWebHistory(),
            base: basePath,
            routes,
            scrollBehavior
        },
);
router.beforeEach((to, from, next) => {
    let destination;

    document.title = capitalize(to.meta?.title ?? to.name ?? to.path) + " | DigiQ";
    if (to.meta.requireLogin && !state.user) {
        destination = getRoute(routes, "track");
        destination.query = {modal: 'login'}
    }
    // if (to.meta.requireAssetAdmin && !asset.__permissions >= permissionThresholds.manageAsset) // todo: get asset from store by id <<<< standardize assetId param across components
    //   destination = getRoute(routes, "Landing");
    if (to.meta.requireAppAdmin && !state.account?.role >= permissionThresholds.manageApp)
        destination = getRoute(routes, "Landing");
    if (to.meta.requireDevOp && !state.account?.role >= permissionThresholds.devOp)
        destination = getRoute(routes, "Landing");
    if (destination?.path === to.path) next();
    else next(destination);
});

export default router;
