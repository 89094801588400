import {state} from "@/store/store";
import {computed, ref, watch} from "vue";
import config from "../../config";
import {useStore} from "vuex";
import {GET_ACCOUNT_ROLES} from "@/store/operations";

const {permissionThresholds, roleNames} = config

export const useUserPermissions = () => {
    const user = computed(() => state.user)
    const isLoggedIn = ref(!!state.user?.repoId)
    const account = computed(() => state.account);
    const store = useStore()
    const userPermission = computed(() => {
        let permission = 0
        if (user.value?.__isDevOp) permission = permissionThresholds.devOp
        else if (user.value?.__isAppAdmin) permission = permissionThresholds.manageApp
        else if (isLoggedIn.value) permission = permissionThresholds.readApp
        return permission
    })
    const roles = computed(() =>
            Object.keys(permissionThresholds)
                    .filter(name => permissionThresholds[name] <= userPermission.value)
                    .map(name => roleNames[name])
                    .filter(n => n)
    )
    const role = computed(() => [...roles.value].pop())

    store.dispatch(GET_ACCOUNT_ROLES).then((result) => result)
    const hasAppRole = (appId, role) =>
            state.user &&
            !!state.account?.roles
                    ?.some(userRole =>
                            userRole.startsWith(appId) &&
                            userRole.endsWith(role)
                    )
            || false

    const hasAssetRole = (appId, repoId, role) => {
        const key = [appId, repoId, role].join('_')
        return state.user &&
                !!state.account?.roles?.includes(key)
                || false
    }

    watch(user, value => {
        isLoggedIn.value = !!value?.repoId
    }, {immediate: true})

    return {
        account,
        getRoleName: permission => roleNames[Object.keys(permissionThresholds).find(k => permissionThresholds[k] === permission)],
        hasAppRole,
        hasAssetRole,
        isLoggedIn,
        role,
        roles,
        user,
        userPermission,
    }
}
