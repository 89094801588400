<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "LResourceCard",
});
</script>

<template>
  <div
    class="lResourceCard bg-white rounded-md shadow-lg border flex transition-all duration-200 hover:shadow-xl cursor-pointer"
  >
    <slot></slot>
  </div>
</template>

<style scoped></style>
