<template>
  <div class="mobility">
    <LayoutHelpline />
    <LayoutTabs />
    <ContactDetails />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import LayoutTabs from "@/components/mobility/LayoutTabs.vue";
import ContactDetails from "@/components/mobility/ContactDetails.vue";
import LayoutHelpline from "@/components/mobility/LayoutHelpline.vue";

export default defineComponent({
  components: {
    LayoutHelpline,
    ContactDetails,
    LayoutTabs,
  },
});
</script>

<style scoped></style>
