import {withPostfix, withPrefix} from "@/lib/typeHelpers/stringFunctions/prefixPostfix";

const parseEditableState = el => {
  const isContentEditable = el.hasAttribute('contenteditable')
  const text = isContentEditable ? el.innerText : el.value
  const start = el.selectionStart
  const end = el.selectionEnd
  return {
    preText: text.substring(0, start),
    selectionRange: {
      start,
      end,
    },
    selectedText: text.substring(start, end),
    postText: text.substring(end),
    text,
  }
}
const mutateState = (state, mutation) => {
  const {
    preText,
    selectionRange,
    selectedText,
    postText,
    text
  } = state

  let start = selectionRange.start
  let end = selectionRange.end

  const {
    prefix,
    replacement,
    postfix,
    collapse
  } = mutation

  const newState = {
    preText,
    selectionRange: {
      start,
      end,
    },
    selectedText,
    postText,
    text,
  }

  const applyTextInsert = (text, {prefix, replacement, postfix}) => {
    let postText
    if (replacement) postText = replacement
    else if (prefix) postText = withPostfix(text, prefix)
    else if (postfix) postText = withPrefix(text, postfix)
    return {
      text: postText,
      addedLength: postText.length - text.length
    }
  }
  if (replacement) {
    const {text, addedLength} = applyTextInsert(selectedText, {replacement})
    newState.selectedText = text
    // place selection end to replacement end
    newState.selectionRange.end += addedLength
  }
  if (prefix) {
    const {text, addedLength} = applyTextInsert(preText, {prefix})
    newState.preText = text
    // move selection back by prefix length
    newState.selectionRange.start += addedLength
    newState.selectionRange.end += addedLength
  }
  if (postfix) {
    const {text} = applyTextInsert(postText, {postfix})
    newState.postText = text
  }

  newState.text = newState.preText + newState.selectedText + newState.postText
  // collapse selection onto its end by default
  if (!collapse === false) newState.selectionRange.start = newState.selectionRange.end
  return newState
}
const applyEditableState = (el, {
  selectionRange,
  text
}) => {
  const isContentEditable = el.hasAttribute('contenteditable')
  // apply value to editable
  if (isContentEditable) el.innerHTML = text
  else el.value = text
  // restore selection range
  el.setSelectionRange(selectionRange.start, selectionRange.end)
  el.focus()
  return text
}
export const useEditableState = editableElementId => {
  const mutateEditorSelection = mutation => {
    /*
        todo:
          per-editor undo history (version per change?)
     */
    const editable = document.getElementById(editableElementId)
    const state = parseEditableState(editable)
    const mutatedState = mutateState(state, mutation)
    applyEditableState(editable, mutatedState)
    return mutatedState.text
  }
  return {
    mutateEditorSelection
  }
}
