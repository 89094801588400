<script>
import WorkFour from "@/components/icons/WorkFour.vue";
import WorkThree from "@/components/icons/WorkThree.vue";
import WorkOne from "@/components/icons/WorkOne.vue";
import WorkFive from "@/components/icons/WorkFive.vue";
import WorkTwo from "@/components/icons/WorkTwo.vue";
import LView from "@/components/layout/LView.vue";
import LSection from "@/components/layout/LSection.vue";

export default {
  name: "DigiQHome",
  components: {
    LSection,
    LView,
    WorkTwo,
    WorkFive,
    WorkOne,
    WorkThree,
    WorkFour
  }
}
</script>

<template>
  <div class="digiQHome">
    <LView>
      <template #body>
          <LSection class="bg-black text-white py-20">
            <template #body>
              <div
                  class="flex k1:flex-row k1:items-center flex-col w-full h-full k1:px-12 py-12 justify-between k1:gap-4"
              >
                <div class="w-2/3">
                  <h1 class="text-6xl font-bold">
                    Transforming the Landscape of Quantum Technology Education
                  </h1>
                  <p class="text-2xl mt-6 font-normal text-gray-300">
                    24 Organisations from 10 countries are changing the way that Quantum
                    Technologies are taught across Europe.
                  </p>
                </div>

                <div class="mt-8 k1:mt-0">
                  <img
                      src="../../../assets/1.jpg"
                      alt=""
                      class="w-full h-[396px]"
                      style="
            transform: perspective(1000px) rotateY(-14deg) scaleY(0.94)
              scaleX(1.04) translateX(-3.5%);
          "
                  />
                  <div class="w-full flex mt-2 text-lg">
                    <div>
                      DigiQ-Module :
                      <a
                          href="https://www.quarksinteractive.com/"
                          target="_blank"
                          class="font-semibold k1:ml-2"
                      >
                        Quantum Odyssey, Quarks Interactive</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </LSection>

          <LSection>
            <template #body>
              <div
                  class="flex k1:flex-row flex-col w-full h-full k1:px-12 py-12 justify-between k1:gap-4"
              >
                <div class="k1:w-[600px] order-2 k1:order-none mt-8 k1:0">
                  <h1 class="text-4xl font-bold m">Mission of DigiQ</h1>
                  <p class="text-xl mt-6 font-normal text-gray-600">
                    In order to meet the emerging need for a quantum-ready workforce in
                    the coming decade, university training efforts within Quantum
                    Technology will not only have to be massively scaled up but also
                    comprehensively reformed. There is an urgent need for a wider
                    understanding of the underpinnings of the quantum revolution as well
                    as an increased awareness for the commercial potential of quantum
                    innovations in the coming generations of quantum physicists.
                    <br><br>
                    The DigiQ
                    project will spearhead a transformation of the educational ecosystem
                    by introducing both a series of didactical innovations as well as a
                    multinational program structure ready to be scaled up to the rest of
                    the European Higher Education Area.
                  </p>
                </div>
                <div class="mt-8 k1:mt-0 k1:py-28">
                  <img src="../../../assets/2.png" alt="" class="w-[528px]"/>
                  <div class="w-full k1:flex mt-2 text-lg">
                    <div>
                      DigiQ-Module :
                      <a
                          href="https://quatomic.com/"
                          target="_blank"
                          class="font-semibold text-black k1:ml-2"
                      >
                        Quantum Composer</a
                      >
                      Aarhus University
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="flex k1:flex-row flex-col w-full h-full k1:px-12 py-12 justify-between k1:gap-4"
              >
                <div class="mt-8 k1:mt-0">
                  <img src="../../../assets/3.jpeg" alt="" class="w-full h-[396px]"/>
                  <div class="w-full flex mt-2 text-lg">
                    <div>
                      DigiQ-Module : Outreach and DEI Training,
                      <a
                          href="https://www.sciencemeltingpot.com/"
                          target="_blank"
                          class="font-semibold text-black k1:ml-2"
                      >
                        Science Melting Pot</a
                      >
                    </div>
                  </div>
                </div>
                <div class="k1:w-[488px] text-center k1:text-start mt-6 k1:mt-0">
                  <h1 class="w-full text-4xl font-bold">Aims and Ambitions</h1>
                  <div
                      class="text-xl text-gray-600 space-y-5 mt-6"
                  >
                    <div class="bg-white border p-6 rounded">
                      Develop 16 Quantum Technology Master degrees across Europe.
                    </div>
                    <div class="bg-white border p-6 rounded">
                      Provide a personalised route for thousands of top students to a
                      Quantum Workforce.
                    </div>
                    <div class="bg-white border p-6 rounded">
                      Disseminate multilingual educational resources for the whole of
                      Europe.
                    </div>
                    <div class="bg-white border p-6 rounded">
                      Help to build the Quantum Industry through a Pan-European mobility
                      program.
                    </div>
                  </div>
                </div>
              </div>

              <div class="container border-t border-t-gray-400 w-full mt-14">
                <div class="title mt-14 flex flex-col items-center">
                  <h1 class="font-bold text-4xl">How is DigiQ Organised?</h1>
                  <p class="text-gray-600 text-2xl w-1/2 text-center pt-6">
                    The project has 5 work packages aimed toward revealing the development
                    of DIGIQ’s vision and work plan.
                  </p>
                </div>
                <div
                    class="flex flex-wrap w-full text-lg items-center justify-center gap-14 mt-14"
                >
                  <div class="col1 basis-[317px]">
                    <div class="icon flex items-center justify-center">
                      <workOne class="bg-white rounded-full shadow-lg"/>
                    </div>
                    <div class="title text-center mt-4">
                      <h1 class="font-semibold text-xl">Work Package 1</h1>
                      <p class="text-gray-600 font-normal">
                        Developing digital learning experiences in Quantum Technology such
                        as ScienceAtHome (AU), Quantum Odyssey (QI), QPlayLearn (UH),
                        SpinDrops (TUM), Decide Game (ICFO), translated to 3 languages.
                      </p>
                    </div>
                  </div>
                  <div class="col2 basis-[317px]">
                    <div class="icon flex items-center justify-center">
                      <workTwo class="bg-white rounded-full shadow-lg"/>
                    </div>
                    <div class="title text-center mt-4">
                      <h1 class="font-semibold text-xl">Work Package 2</h1>
                      <p class="text-gray-600 font-normal">
                        Aligning teaching/learning courses delivered in three different
                        formats: augmented, online and in innovative experimental formats.
                      </p>
                    </div>
                  </div>
                  <div class="col3 basis-[317px]">
                    <div class="icon flex items-center justify-center">
                      <workThree class="bg-white rounded-full shadow-lg"/>
                    </div>
                    <div class="title text-center mt-4">
                      <h1 class="font-semibold text-xl">Work Package 3</h1>
                      <p class="text-gray-600 font-normal">
                        Establishing and operating DQ-networks to form the central human
                        element of the DigiQ program, providing structured and
                        individually tailored training for every DigiQ student.
                      </p>
                    </div>
                  </div>
                  <div class="col4 basis-[317px]">
                    <div class="icon flex items-center justify-center">
                      <workFour class="bg-white rounded-full shadow-lg"/>
                    </div>
                    <div class="title text-center mt-4">
                      <h1 class="font-semibold text-xl">Work Package 4</h1>
                      <p class="text-gray-600 font-normal">
                        Facilitating exchange semesters in which students may access the
                        didactic innovations of the developed DQ-courses, thesis projects,
                        and establishing the DQ-Internship program based at nodes in the
                        research and industry centres.
                      </p>
                    </div>
                  </div>
                  <div class="col5 basis-[317px]">
                    <div class="icon flex items-center justify-center">
                      <workFive class="bg-white rounded-full shadow-lg"/>
                    </div>
                    <div class="title text-center mt-4">
                      <h1 class="font-semibold text-xl">Work Package 5</h1>
                      <p class="text-gray-600 font-normal">
                        A shared infrastructure for the implementation of the DQ-Master
                        program across all partner formats: maintaining alignment between
                        the educational programs offered at the partners and tracking
                        student participation in the DQ-Master.
                      </p>
                    </div>
                  </div>
                </div>
              </div>


              <div class="container border-t border-t-gray-400 w-full mt-14">
                <div class="title mt-14 flex flex-col items-center">
                  <h1 class="font-bold text-4xl">Diversity, Equity, and Inclusion (DEI)</h1>
                  <p class="text-gray-600 text-2xl w-2/3 text-center pt-6">
                    We are committed to fostering an environment that embraces diversity, ensures equity, and promotes inclusivity for all. At DigiQ, we aim to provide equal opportunities and accessibility to everyone, regardless of their background, identity, or abilities.
                  </p>
                </div>
                <div
                    class="flex flex-wrap w-full text-lg items-center justify-center gap-14 mt-14"
                >
                  <div class="col1 basis-[317px]">
                    <div class="title text-center mt-4">
                      <a class="text-blue-600 text-xs"
                         href="diversity.pdf"
                         target="_blank"
                      >
                      <h1 class="font-semibold text-lg">Download General Guidelines</h1>
                      </a>
                      <p class="text-gray-600 font-normal">
                        Shaping Our Approach to Diversity, Equity, and Inclusion
                      </p>
                    </div>
                  </div>

                  <div class="col2 basis-[317px]">
                    <div class="title text-center mt-4">
                      <a class="text-blue-600 text-xs"
                         href="inclusive.pdf"
                         target="_blank"
                      >
                      <h1 class="font-semibold text-lg">Download Inclusive Communication Guidelines</h1>
                      </a>
                      <p class="text-gray-600 font-normal">
                        Fostering Inclusivity in Communication
                      </p>
                    </div>
                  </div>

                  <div class="col3 basis-[317px]">
                    <div class="title text-center mt-4">
                      <a class="text-blue-600 text-xs"
                         href="Selection Guidelines.pdf"
                         target="_blank"
                      >
                      <h1 class="font-semibold text-lg">Download Guidelines for selection procedures for all DigiQ actions</h1>
                      </a>
                      <p class="text-gray-600 font-normal">
                        Advancing Equity in DigiQ Selection Processes
                      </p>
                    </div>
                  </div>

                </div>
              </div>


            </template>
          </LSection>
      </template>
    </LView>
  </div>
</template>

<style scoped>

</style>