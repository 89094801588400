import {
    _PUT_STATUS,
    APPLY_FOR_COURSE,
    APPLY_FOR_INTERNSHIP,
    APPLY_FOR_NETWORK_COMMITMENT,
    CREATE_COURSE,
    CREATE_ECTS_MILESTONE,
    CREATE_INTERNSHIP,
    CREATE_MILESTONE,
    CREATE_NETWORK_COMMITMENT,
    DELETE_UNENROLLED_UNAWARDED_MILESTONE,
    DELIST_COMMITMENT,
    ENROLL_APPLICANTS,
    EXPEL_STUDENT,
    LIST_APPLICANTS,
    LIST_COMMITMENTS,
    LIST_COMMITMENT_PEOPLE,
    LIST_DRAFTS,
    LIST_HOSTED_COMMITMENTS,
    LOAD_COMMITMENT_MILESTONE_ENROLMENT_ACCOUNTS,
    LOAD_COMMITMENT_MILESTONE_ENROLMENT_ACCOUNTS_AWARDS,
    REJECT_STUDENT,
    SELECT_MODULE,
    TRIGGER_MILESTONE,
    PUBLISH_COURSE,
    PUBLISH_INTERNSHIP,
    PUBLISH_NETWORK_COMMITMENT,
    DELETE_UNENROLLED_UNAWARDED_COURSE,
    DELETE_UNENROLLED_UNAWARDED_INTERNSHIP,
    DELETE_UNENROLLED_UNAWARDED_NETWORK_COMMITMENT,
    RETRACT_UNENROLLED_UNAWARDED_COURSE,
    RETRACT_UNENROLLED_UNAWARDED_INTERNSHIP,
    RETRACT_UNENROLLED_UNAWARDED_NETWORK_COMMITMENT,
    LIST_ASSETS,
    GET_ASSET,
    GET_COMMITMENT, SET_COURSE_ACCREDITED_AT, RESET_COURSE_ACCREDITED_AT,
} from "@/store/operations";
import axios from "@/plugins/axios";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import {
    getAssetActionUrl,
    getAssetUrl,
    getChildAssetActionUrl,
} from "@/store/lib/apiUrls";

export default {
    [CREATE_COURSE](context, {universityRepoId, universityId, attributes}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getAssetActionUrl({
                dataType: 'University',
                postfix: universityRepoId,
                actionName: 'createCourse',
            }),
            params: {
                universityId,
                attributes
            }
        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: CREATE_COURSE, result: response.data})
                    return response

                })
    },
    [CREATE_INTERNSHIP](context, {universityRepoId, universityId, attributes}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getAssetActionUrl({
                dataType: 'University',
                postfix: universityRepoId,
                actionName: 'createInternship',
            }),
            params: {
                universityId,
                attributes
            }
        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: CREATE_INTERNSHIP, result: response.data})
                    return response

                })
    },
    [CREATE_NETWORK_COMMITMENT](context, {universityRepoId, universityId, attributes}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getAssetActionUrl({
                dataType: 'University',
                postfix: universityRepoId,
                actionName: 'createNetworkCommitment',
            }),
            params: {
                universityId,
                attributes
            }
        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: CREATE_NETWORK_COMMITMENT, result: response.data})
                    return response
                })
    },

    [SELECT_MODULE](context, {universityRepoId, repoId, update}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'putModule',
            }),
            params: {
                update
            }
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: SELECT_MODULE, result: response.data})
                    return response

                })
    },
    [CREATE_MILESTONE](context, {universityRepoId, commitmentRepoId, commitmentId, attributes}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: commitmentRepoId,
                actionName: 'addMilestone',
            }),
            params: {
                commitmentId,
                attributes
            }
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: CREATE_MILESTONE, result: response.data})
                    return response

                })
    },
    [CREATE_ECTS_MILESTONE](context, {universityRepoId, commitmentRepoId, commitmentId, attributes}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: commitmentRepoId,
                actionName: 'addECTSMilestone',
            }),
            params: {
                commitmentId,
                attributes
            }
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: CREATE_ECTS_MILESTONE, result: response.data})
                    return response

                })
    },
    [SET_COURSE_ACCREDITED_AT](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.put,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'courseAccreditedAt',
            }),
        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: SET_COURSE_ACCREDITED_AT, result: response.data})
                    return response

                })
    },
    [RESET_COURSE_ACCREDITED_AT](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.delete,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'courseAccreditedAt',
            }),
        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: RESET_COURSE_ACCREDITED_AT, result: response.data})
                    return response

                })
    },
    [PUBLISH_COURSE](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.put,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'publishCourse',
            }),
            params: {dataType: 'Course'},
        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: PUBLISH_COURSE, result: response.data})
                    return response

                })
    },
    [PUBLISH_INTERNSHIP](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.put,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'publishInternship',
            }),
            params: {dataType: 'Internship'},
        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: PUBLISH_INTERNSHIP, result: response.data})
                    return response

                })
    },
    [PUBLISH_NETWORK_COMMITMENT](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.put,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'publishNetworkCommitment',
            }),
            params: {dataType: 'NetworkCommitment'},
        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: PUBLISH_NETWORK_COMMITMENT, result: response.data})
                    return response

                })
    },
    [GET_COMMITMENT](context, {dataType, repoId}) {
        return throttleBufferedAction({
            action: axios.get,
            url: getAssetActionUrl({
                dataType: dataType,
                postfix: repoId,
                actionName: 'getCommitment',
            }),

        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: GET_COMMITMENT, result: response.data})
                    return response

                })
    },
    [LIST_COMMITMENT_PEOPLE](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.get,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'listPeople',
            }),

        })
                .then(response => {
                    context.commit(LIST_ASSETS, response.data)
                    context.commit(_PUT_STATUS, {event: LIST_COMMITMENT_PEOPLE, result: response.data})
                    return response
                })
    },
    [LIST_DRAFTS](context, {universityRepoId}) {
        return throttleBufferedAction({
            action: axios.get,
            url: getAssetActionUrl({
                dataType: 'University',
                postfix: universityRepoId,
                actionName: 'listDrafts',
            }),

        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: LIST_DRAFTS, result: response.data})
                    return response

                })
    },
    [DELETE_UNENROLLED_UNAWARDED_COURSE](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.delete,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'deleteUnenrolledUnawardedCourse',
            }),

        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: DELETE_UNENROLLED_UNAWARDED_COURSE, result: response.data})
                    return response

                })
    },
    [DELETE_UNENROLLED_UNAWARDED_INTERNSHIP](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.delete,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'deleteUnenrolledUnawardedInternship',
            }),

        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: DELETE_UNENROLLED_UNAWARDED_INTERNSHIP, result: response.data})
                    return response

                })
    },
    [DELETE_UNENROLLED_UNAWARDED_NETWORK_COMMITMENT](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.delete,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'deleteUnenrolledUnawardedNetworkCommitment',
            }),

        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: DELETE_UNENROLLED_UNAWARDED_NETWORK_COMMITMENT, result: response.data})
                    return response

                })
    },
    [DELETE_UNENROLLED_UNAWARDED_MILESTONE](context, {universityRepoId, commitmentRepoId, milestoneRepoId}) {
        return throttleBufferedAction({
            action: axios.delete,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: commitmentRepoId,
                actionName: 'deleteUnenrolledUnawardedMilestone',
            }),
            params: {
                milestoneRepoId,
            }
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: DELETE_UNENROLLED_UNAWARDED_MILESTONE, result: response.data})
                    return response
                })
    },
    [RETRACT_UNENROLLED_UNAWARDED_COURSE](context, {universityRepoId,  repoId}) {
        return throttleBufferedAction({
            action: axios.put,
            url: getChildAssetActionUrl({
                dataType: 'Course',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'retractUnenrolledUnawardedCourse',
            }),

        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: RETRACT_UNENROLLED_UNAWARDED_COURSE, result: response.data})
                    return response

                })
    },
    [RETRACT_UNENROLLED_UNAWARDED_INTERNSHIP](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.put,
            url: getChildAssetActionUrl({
                dataType: 'Internship',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'retractUnenrolledUnawardedInternship',
            }),

        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: RETRACT_UNENROLLED_UNAWARDED_INTERNSHIP, result: response.data})
                    return response

                })
    },
    [RETRACT_UNENROLLED_UNAWARDED_NETWORK_COMMITMENT](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.put,
            url: getChildAssetActionUrl({
                dataType: 'NetworkCommitment',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'retractUnenrolledUnawardedNetworkCommitment',
            }),

        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: RETRACT_UNENROLLED_UNAWARDED_NETWORK_COMMITMENT, result: response.data})
                    return response

                })
    },
    [APPLY_FOR_COURSE](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'applyForCourse',
            }),
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: APPLY_FOR_COURSE, result: response.data})
                    return response
                })
    },
    [APPLY_FOR_INTERNSHIP](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'applyForInternship',
            }),
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: APPLY_FOR_INTERNSHIP, result: response.data})
                    return response
                })
    },
    [APPLY_FOR_NETWORK_COMMITMENT](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'applyForNetworkCommitment',
            }),
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: APPLY_FOR_NETWORK_COMMITMENT, result: response.data})
                    return response
                })
    },
    [TRIGGER_MILESTONE](context, {universityRepoId, commitmentRepoId, milestoneRepoId}) {
        return throttleBufferedAction({
            action: axios.put,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: commitmentRepoId,
                actionName: 'triggerMilestone',
            }),
            params: {
                milestoneRepoId,
            }
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: TRIGGER_MILESTONE, result: response.data})
                    return response
                })
    },
    [DELIST_COMMITMENT](context, {universityRepoId, repoId}) {
        return throttleBufferedAction({
            action: axios.put,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: repoId,
                actionName: 'delistCommitment',
            }),
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: DELIST_COMMITMENT, result: response.data})
                    return response
                })
    },
    [LOAD_COMMITMENT_MILESTONE_ENROLMENT_ACCOUNTS](context, {
        universityRepoId,
        commitmentRepoId,
    }) {
        return throttleBufferedAction({
            action: axios.get,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: commitmentRepoId,
                actionName: 'loadCommitmentMilestoneEnrolmentAccounts',
            }),
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {
                        event: LOAD_COMMITMENT_MILESTONE_ENROLMENT_ACCOUNTS,
                        result: response.data
                    })
                    return response
                })
    },
    [LOAD_COMMITMENT_MILESTONE_ENROLMENT_ACCOUNTS_AWARDS](context, {
       universityRepoId,
          commitmentRepoId,
    }) {
        return throttleBufferedAction({
            action: axios.get,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: commitmentRepoId,
                actionName: 'loadCommitmentMilestoneEnrolmentAccountsAwards',
            })
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {
                        event: LOAD_COMMITMENT_MILESTONE_ENROLMENT_ACCOUNTS_AWARDS,
                        result: response.data
                    })
                    return response
                })
    },
    [LIST_APPLICANTS](context, {universityRepoId, commitmentRepoId, filter, sort, offset}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: commitmentRepoId,
                actionName: 'listApplicants',
            }),
            params: {
                filter,
                sort,
                offset,
            }
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: LIST_APPLICANTS, result: response.data})
                    return response
                })
    },
    [ENROLL_APPLICANTS](context, {universityRepoId, commitmentRepoId}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: commitmentRepoId,
                actionName: 'enrollApplicants',
            }),
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: ENROLL_APPLICANTS, result: response.data})
                    return response
                })
    },
    [REJECT_STUDENT](context, {universityRepoId, commitmentRepoId, studentRepoId}) {
        return throttleBufferedAction({
            action: axios.delete,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: commitmentRepoId,
                actionName: 'rejectStudent',
            }),
            params: {
                studentRepoId,
            }
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: REJECT_STUDENT, result: response.data})
                    return response
                })
    },
    [EXPEL_STUDENT](context, {universityRepoId, commitmentRepoId, studentRepoId}) {
        return throttleBufferedAction({
            action: axios.delete,
            url: getChildAssetActionUrl({
                dataType: 'University',
                parentRepoId: universityRepoId,
                childRepoId: commitmentRepoId,
                actionName: 'expelStudent',
            }),
            params: {
                studentRepoId,
            }
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: EXPEL_STUDENT, result: response.data})
                    return response
                })
    },
    [LIST_HOSTED_COMMITMENTS](context, {universityRepoId, filter, sort, offset}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getAssetActionUrl({
                dataType: 'University',
                repoId: universityRepoId,
                actionName: 'listHostedCommitments',
            }),
            params: {
                filter,
                sort,
                offset,
            }
        })
                .then(response => {
                    context.commit(_PUT_STATUS, {event: LIST_HOSTED_COMMITMENTS, result: response.data})
                    return response
                })
    },
    [LIST_COMMITMENTS](context) {
        return throttleBufferedAction({
            action: axios.post,
            url: getAssetUrl({
                dataType: 'University',
                postfix: 'listCommitments',
            }),
        })
                .then(response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: LIST_COMMITMENTS, result: response.data})
                    return response
                })
    }
}