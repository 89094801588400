export default [
    {
        path: "/forgotPassword",
        name: "forgotpassword",
        props: true,
        component: () => import("../../components/authentication/ForgotPassword.vue"),
    },
    {
        path: "/signUp",
        name: "RegisterUserAccount",
        props: true,
        component: () =>
            import("../../components/authentication/RegisterDigiQTAccount.vue"),
    },
    {
        path: "/activateAccount/:activateAccountToken",
        props: true,
        component: () =>
            import("../../components/views/authentication/ActivateAccountWithToken.vue"),
    },
    {
        path: "/resetPassphrase/:resetPassphraseToken",
        props: true,
        component: () =>
            import("../../components/views/authentication/ResetPassphraseWithToken.vue"),
    },
    {
        path: "/changeAccountEmail/:changeAccountEmailToken",
        props: true,
        component: () =>
            import("../../components/views/authentication/ChangeAccountEmailWithToken.vue"),
    },
    {
        path: "/activateNewEmail/:activateNewEmailToken",
        props: true,
        component: () =>
            import("../../components/views/authentication/ActivateNewEmailWithToken.vue"),
    },
    {
        path: "/downloadMyData/:downloadMyDataToken",
        props: true,
        component: () =>
            import("../../components/views/authentication/DownloadMyDataWithToken.vue"),
    },
    {
        path: "/forgetAboutMe/:forgetAboutMeToken",
        props: true,
        component: () =>
            import("../../components/views/authentication/ForgetAboutMeWithToken.vue"),
    },
    {
        path: "/reviewAppRoleRequest/:roleRequestToken",
        props: true,
        component: () =>
            import("../../components/views/authentication/ReviewAppRoleRequest.vue"),
        meta: {
            requireAppAdmin: true,
        }
    },
    {
        path: "/reviewUniRepRoleTakeoverRequest/:roleRequestToken",
        props: true,
        component: () =>
            import("../../components/views/authentication/ReviewUniRepRoleTakeoverRequest.vue"),
        meta: {
            requireAppAdmin: true,
        }
    },
    {
        path: "/reviewRoleRequest/:roleRequestToken",
        props: true,
        component: () =>
                import("../../components/views/authentication/ReviewRoleRequest.vue"),
        meta: {
            requireAppAdmin: true,
        }
    },
    {
        path: "/confirmStatusRequest/:roleRequestToken",
        props: true,
        component: () =>
                import("../../components/views/authentication/ConfirmStatus.vue"),
        meta: {
            requireAppAdmin: true,
        }
    },
]