<template>
  <svg width="72" height="72" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="nonzero" fill="none">
      <path d="M29 25h-3a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h3V25z" fill="#6BCFFF" />
      <path
        d="M31 47h10V25H31v22zm5-4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0-14a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
        fill="#2F75FF"
      />
      <path d="M46 25h-3v22h3a1 1 0 0 0 1-1V26a1 1 0 0 0-1-1z" fill="#FFC760" />
    </g>
  </svg>
</template>
