import store, {state} from "../store/store";
import {computed} from "vue";
import {DELETE_ASSET, GET_ASSET, PUBLISH_ASSET, PUT_ASSET, RETRACT_ASSET} from "@/store/operations";
import {immutableParams} from "@/composables/useDocument";

export const useGenericDocument = (dataType, repoId, id, options) => {
    if (!dataType) throw new Error('useGenericDocument requires a document dataType')
    if (!repoId) throw new Error('useGenericDocument requires a repoId')

    options = options ?? {}

    const document = computed(() =>
            state.assets?.[dataType]?.[id] ??
            Object.values(state.assets[dataType] ?? {})
                    .find(doc => doc.repoId === repoId) ?? {}
    )

    const loadDocument = refresh => {
        const loaded = state.assets[dataType]?.[id] ??
                Object.values(state.assets[dataType] ?? {})
                        .find(doc => doc.repoId === repoId)
        if (!loaded || refresh || options?.refresh) {
            const params = {
                dataType,
                repoId,
            }
            if (options.permissionHostDataType) params.permissionHostDataType = options.permissionHostDataType
            if (options.permissionHostRepoId) params.permissionHostRepoId = options.permissionHostRepoId

            store.dispatch(options.loadAction || GET_ASSET, params)
        }
    }

    const publishDocument = () => store.dispatch(PUBLISH_ASSET, {dataType, repoId})
    const retractDocument = () => store.dispatch(RETRACT_ASSET, {dataType, repoId})

    loadDocument()

    const normalizeParams = updated => {
        const update = {}
        Object.keys(updated).forEach(key => {
            if (!key.startsWith('_')) {
                if (!immutableParams.includes(key)) update[key] = updated[key]
            }
        })
        return update
    }
    const updateDocument = updated => store.dispatch(PUT_ASSET, {
        dataType,
        repoId,
        update: normalizeParams(updated),
    })
            .then(result => {
                console.log(83, result)
                return result
            })

    const deleteDocument = () => store.dispatch(DELETE_ASSET, {dataType, repoId})

    return {
        deleteDocument,
        document,
        loadDocument,
        publishDocument,
        retractDocument,
        updateDocument,
    }
}
