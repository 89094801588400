import * as operations from '../store/operations'
import {computed, isRef, reactive} from "vue";
import {state} from "@/store/store";

const assertOperationExists = operation => {
    if (!operations[operation]) throw new Error(`Invalid operation: ${operation}`)
}
export const useStatusMessage = operation => {
    if (operation) assertOperationExists(operation)
    if (!isRef(state.status[operation])) state.status[operation] = reactive(state.status?.[operation] ?? {})

    const message = computed(() => state.status[operation])

    const deleteMessage = () => delete state.status[operation]
    const setMessage = newMessage => state.status[operation] = reactive(newMessage)

    const acknowledgeMessage = () => delete state.status[operation]

    return {
        acknowledgeMessage,
        deleteMessage,
        message,
        setMessage,
    }
}