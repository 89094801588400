<template>
  <div
    class="dropdownSingle relative"
    @keydown.enter.stop="toggle"
    @keydown.space.prevent="toggle"
    @keydown.up.prevent="focusPrev"
    @keydown.down.prevent="focusNext"
    @keydown.tab="onFocusNextEl"
  >
    <button
      :class="['button', 'dropdown', disable ? 'opacity-70' : '']"
      type="button"
      :key="dropdown"
      v-bind="$attrs"
      tabindex="0"
      @click.stop="toggle()"
    >
      {{ title || "Select an item" }}
      <svg
        class="ml-2 w-4 h-4"
        aria-hidden="true"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </button>
    <!-- Dropdown menu -->
    <div
      v-show="show"
      class="z-10 w-56 bg-white h-36 overflow-y-auto rounded divide-y divide-gray-100 shadow dark:bg-gray-700"
      style="
        position: absolute;
        inset: 0px auto auto 0px;
        margin: 0px;
        transform: translate3d(0px, 44px, 0px);
      "
    >
      <div v-if="showSearch" class="px-4 py-2">
        <input
          type="text"
          v-model="search"
          :tabindex="$attrs.tabindex || 1"
          autofocus
          class="w-full py-1 px-2 text-sm text-gray-700 rounded dark:text-gray-200"
          placeholder="Search items..."
          ref="searchInput"
          @keydown.enter.prevent.stop="selectFocused"
          @keydown.down.stop="focusNext"
          @keydown.up.stop="focusPrev"
        />
      </div>
      <ul
        class="py-1 text-sm text-gray-700 dark:text-gray-200"
        @keydown.down.prevent="focusNext"
        @keydown.up.prevent="focusPrev"
        @keydown.enter.stop="selectFocused"
        @keydown.space.prevent.stop="selectFocused"
      >
        <li
          v-for="(item, index) in filteredItems"
          :key="item"
          @click.stop="onClick"
        >
          <div
            :id="item"
            :class="[
              'block py-2 px-4 ',
              index === focusedIndex ? 'bg-gray-200 dark:text-gray-700' : '',
              'hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
            ]"
            @mouseover="focusedIndex = index"
          >
            {{ item }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { isString } from "@/lib/getVariableType";
import dropdown from "@/components/forms/Dropdown.vue";

export default {
  name: "DropdownSingle",
  computed: {
    dropdown() {
      // ?
      return dropdown;
    },
  },
  emits: ["select"],
  props: {
    title: {
      type: String,
    },
    items: {
      // first commitment is the 'all' option
      type: Array,
      required: true,
      validator: (v) =>
        Array.isArray(v) &&
        v.reduce((pass, item) => pass && isString(item), true),
    },
    disable: {
      type: Boolean,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const show = ref(false);
    const searchInput = ref(null);
    const search = ref("");

    const onClick = (e) => {
      e.preventDefault(); // do not bubble to button
      show.value = false;
      emit("select", e.target.id);
    };
    const closeDropdown = () => {
      show.value = false;
    };
    const onClickOutside = (event) => {
      const dropdownElement = event.target.closest(".dropdown.relative");
      const isSearchInput = event.target === searchInput.value;

      if (!dropdownElement && !isSearchInput) {
        closeDropdown();
      }
    };
    onMounted(() => {
      document.addEventListener("click", onClickOutside);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("click", onClickOutside);
    });
    const toggle = () => {
      if (show.value) {
        search.value = "";
        selectFocused();
      } else {
        show.value = !show.value;
      }
    };
    const filteredItems = computed(() =>
      props.items.filter((item) =>
        item.toLowerCase().includes(search.value.toLowerCase())
      )
    );
    const focusedIndex = ref(-1);
    const focusNext = () => {
      focusedIndex.value =
        focusedIndex.value === filteredItems.value.length - 1
          ? 0
          : focusedIndex.value + 1;
    };
    const focusPrev = () => {
      focusedIndex.value =
        focusedIndex.value === 0
          ? filteredItems.value.length - 1
          : focusedIndex.value - 1;
    };
    const selectFocused = () => {
      if (focusedIndex.value !== -1) {
        show.value = false;
        emit("select", filteredItems.value[focusedIndex.value]);
      }
    };

    const onFocusNextEl = (e) => {
      if (
        show.value &&
        props.showSearch &&
        document.activeElement !== searchInput.value
      ) {
        searchInput.value.focus();
        e.stopPropagation();
        e.preventDefault();
      } else {
        show.value = false;
      }
    };
    return {
      onClick,
      show,
      toggle,
      search,
      searchInput,
      filteredItems,
      focusedIndex,
      focusNext,
      focusPrev,
      onFocusNextEl,
      selectFocused,
    };
  },
};
</script>

<style scoped>
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light grey background */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px; /* Height of the scrollbar, for horizontal scrollbars */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Dark grey handle */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid transparent; /* Creates padding around the handle */
  background-clip: padding-box; /* Ensures the border doesn't overlap the background */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker grey on hover */
}
</style>
