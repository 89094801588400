import {
    _PUT_STATUS,
    DEMOTE_ASSET_USER,
    EXPEL_ASSET_USER,
    LIST_ASSET_MEMBERS,
    LIST_ASSETS,
    PROMOTE_ASSET_USER
} from "@/store/operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";
import {getAssetUserActionUrl} from "@/store/lib/apiUrls";

export default {
    [LIST_ASSET_MEMBERS](
        context,
        {
            dataType,
            repoId,
            permissionHostDataType,
            permissionHostRepoId,
            offset,
            limit,
            sorting,
            filter
        }) {
        const options = {
            permissionHostDataType,
            permissionHostRepoId,
            offset,
            limit,
            sorting,
            filter: JSON.stringify(filter)
        }
        return throttleBufferedAction({
            action: axios.post,
            url: getAssetUserActionUrl(dataType, repoId, 'users'),
            params: options
        })
            .then(response => {
                context.commit(
                    LIST_ASSETS,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: LIST_ASSETS, result: response.data})
                return response
            })
    },
    [DEMOTE_ASSET_USER](context, {dataType, repoId, userRepoId}) {
        return axios.put(
            getAssetUserActionUrl(dataType, repoId, 'demote'),
            {userRepoId}
        )
            .then(response => {
                context.commit(
                    LIST_ASSETS,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: DEMOTE_ASSET_USER, result: response.data})
                return response
            })
    },
    [EXPEL_ASSET_USER](context, {dataType, repoId, userRepoId}) {
        return axios.put(
            getAssetUserActionUrl(dataType, repoId, 'expel'),
            {userRepoId}
        )
            .then(response => {
                context.commit(
                    LIST_ASSETS,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: EXPEL_ASSET_USER, result: response.data})
                return response
            })
    },
    [PROMOTE_ASSET_USER](context, {dataType, repoId, userRepoId}) {
        return axios.put(
            getAssetUserActionUrl(dataType, repoId, 'promote'),
            {userRepoId}
        )
            .then(response => {
                context.commit(
                    LIST_ASSETS,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: PROMOTE_ASSET_USER, result: response.data})
                return response
            })
    },
}