<template>
  <div class="LFieldError fieldError__message text-red-800 text-sm">
    <slot>
      <span>{{ message }}</span>
    </slot>
  </div>
</template>

<script>
export default {
  name: "LFieldError",
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
