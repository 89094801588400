<script>
import Credential from "@/components/track/CredentialWallet/Credential.vue";
import {computed} from "vue";

export default {
  name: "Credentials",
  components: {
    Credential
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    useColumns: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const credentials = computed(() => [...(new Set(props.list))])
    return {
      credentials
    }
  }
}
</script>

<template>
<div
    v-if="credentials.length"
    class="
        credentials
        bg-white
        rounded-md
        gap-2
    "
    :class="{
      'grid grid-cols-1 k14:grid-cols-2': useColumns,
      'flex flex-col': !useColumns
    }"
>
  <credential
      v-for="cred in credentials"
      :key="cred"
      :credential="cred"
  >
  </credential>
</div>
</template>

<style scoped>

</style>