import {
    _PUT_STATUS,
    ACTIVATE_ACCOUNT,
    ACTIVATE_NEW_EMAIL,
    CHANGE_PASSWORD,
    CONFIRM_CHANGE_EMAIL,
    CONFIRM_DOWNLOAD_MY_DATA,
    CONFIRM_FORGET_ABOUT_ME,
    CONFIRM_RESET_PASSPHRASE,
    GET_ASSET,
    GET_USER,
    LIST_ASSETS,
    LIST_USERS,
    LOGIN,
    LOGOUT,
    REQUEST_CHANGE_EMAIL,
    REQUEST_FORGET_USER,
    REQUEST_PASSWORD_RESET,
    _RESET_ASSETS,
    // REQUEST_ROLE,
    GRANT_ROLE,
    REVOKE_ROLE,
    GET_USER_AWARDS,
    VERIFY_ROLE_REQUEST,
    DENY_ROLE,
    VERIFY_APP_ROLE_REQUEST,
    VERIFY_ACTIVATE_ACCOUNT_TOKEN,
    GET_ACCOUNT_ROLES
} from "@/store/operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";
import {
    getAssetActionUrl,
    getUserAccountActionUrl,
    getUserAccountConfirmationUrl,
    getUserAccountsUrl,
    getUserAccountUrl
} from "@/store/lib/apiUrls";
import {state} from "@/store/store";

export default {

    [LIST_USERS](context, {offset, pageSize, filter}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getUserAccountsUrl(),
            params: {
                offset,
                pageSize,
                filter: JSON.stringify(filter)
            }
        })
                .then(async response => {
                    context.commit(
                            LIST_ASSETS,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: LIST_USERS, result: response.data})
                    return response
                })
    },
    [GET_USER](context, repoId) {
        return throttleBufferedAction({
            action: axios.get,
            url: getUserAccountUrl(repoId)
        })
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: GET_USER, result: response.data})
                    return response
                })
    },
    [LOGIN](context, {email, password}) {
        return axios.post(
                getUserAccountActionUrl(email, 'login'),
                {
                    password
                },
        )
                .then(async response => {
                    context.commit(
                            LOGIN,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: LOGIN, result: response.data})

                    return response
                })
    },
    [LOGOUT](context) {
        context.commit(_RESET_ASSETS)
        return axios.put(
                getUserAccountUrl('logout'),
        )
                .then(() => {
                    context.commit(_PUT_STATUS, {event: LOGOUT})
                })
                .finally(() =>
                        context.commit(LOGOUT)
                )
    },
    [CHANGE_PASSWORD](context, {id, passphrase, newPassphrase}) {
        return axios.put(
                getAssetActionUrl({id, actionName: 'password'}),
                {
                    passphrase,
                    newPassphrase
                }
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: CHANGE_PASSWORD, result: response.data})
                    return response
                })
    },

    [REQUEST_FORGET_USER](context, id) {
        return axios.post(
                getUserAccountActionUrl(id, 'forgetMe'),
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: REQUEST_FORGET_USER, result: response.data})
                    return response
                })
    },
    [REQUEST_PASSWORD_RESET](context, email) {
        return axios.post(
                getUserAccountActionUrl(email, 'resetPassphrase'),
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: REQUEST_PASSWORD_RESET, result: response.data})
                    return response
                })
    },
    [REQUEST_CHANGE_EMAIL](context, {id, newEmail}) {
        return axios.post(
                getUserAccountActionUrl(id, 'changeEmail'),
                {
                    newEmail
                }
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: REQUEST_CHANGE_EMAIL, result: response.data})
                    return response
                })
    },

    // with token
    [ACTIVATE_ACCOUNT](
            context,
            {
                activateAccountToken,
                name,
                password,
            }
    ) {
        return axios.post(
                getUserAccountConfirmationUrl(
                        'activateAccount',
                        activateAccountToken
                ),
                {
                    name,
                    password,
                }
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: ACTIVATE_ACCOUNT, result: response.data})
                    return response
                })
    },
    [VERIFY_ACTIVATE_ACCOUNT_TOKEN](
            context,
            {activateAccountToken},
    ) {
        return axios.post(
                getUserAccountConfirmationUrl(
                        'verifyActivateAccountToken',
                        activateAccountToken
                )
        )
                .then(async response => {
                    context.commit(GET_ASSET, response.data)
                    context.commit(_PUT_STATUS, {event: VERIFY_ACTIVATE_ACCOUNT_TOKEN, result: response.data})
                })
    },
    [VERIFY_ROLE_REQUEST](
            context,
            verifyRoleRequestToken,
    ) {
        return axios.post(
                getUserAccountConfirmationUrl(
                        'verifyRoleRequest',
                        verifyRoleRequestToken
                ),
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: VERIFY_ROLE_REQUEST, result: response.data})
                    return response
                })
    },
    [VERIFY_APP_ROLE_REQUEST](
            context,
            verifyRoleRequestToken,
    ) {
        return axios.post(
                getUserAccountConfirmationUrl(
                        'verifyAppRoleRequest',
                        verifyRoleRequestToken
                ),
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: VERIFY_APP_ROLE_REQUEST, result: response.data})
                    return response
                })
    },
    [CONFIRM_RESET_PASSPHRASE](context, {resetPassphraseToken, newPassphrase}) {
        return axios.post(
                getUserAccountConfirmationUrl('resetPassphrase', resetPassphraseToken),
                {
                    newPassphrase
                },
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: CONFIRM_RESET_PASSPHRASE, result: response.data})
                    return response
                })
    },
    [CONFIRM_DOWNLOAD_MY_DATA](context, {downloadMyDataToken}) {
        return axios.post(
                getUserAccountConfirmationUrl('downloadMyData', downloadMyDataToken),
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: CONFIRM_DOWNLOAD_MY_DATA, result: response.data})
                    return response
                })
    },
    [CONFIRM_FORGET_ABOUT_ME](context, {forgetAboutMeToken}) {
        return axios.post(
                getUserAccountConfirmationUrl('forgetAboutMe', forgetAboutMeToken),
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: CONFIRM_DOWNLOAD_MY_DATA, result: response.data})
                    return response
                })
    },
    [CONFIRM_CHANGE_EMAIL](context, {changeEmailToken}) {
        return axios.post(
                getUserAccountConfirmationUrl('changeEmail', changeEmailToken),
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: CONFIRM_CHANGE_EMAIL, result: response.data})
                    return response
                })
    },
    [ACTIVATE_NEW_EMAIL](context, {newEmailToken}) {
        return axios.post(
                getUserAccountConfirmationUrl('activateNewEmail', newEmailToken),
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: ACTIVATE_NEW_EMAIL, result: response.data})
                    return response
                })
    },
    // [REQUEST_ROLE](context, {role}) {
    //     return axios.post(
    //             getUserAccountUrl('requestRole') + `/${role}`,
    //     )
    //             .then(async response => {
    //                 context.commit(
    //                         GET_ASSET,
    //                         response.data
    //                 )
    //                 context.commit(_PUT_STATUS, {event: REQUEST_ROLE, result: response.data})
    //                 return response
    //             })
    // },
    [GRANT_ROLE](context, {roleEntry, userRepoId}) {
        return axios.post(
                getAssetActionUrl({
                    dataType: 'Account/grantRole',
                    postfix: userRepoId,
                    actionName: roleEntry
                }),
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: GRANT_ROLE, result: response.data})
                    return response
                })
    },
    [DENY_ROLE](context, {roleEntry, userRepoId, assetRepoId, reason=''}) {
        return axios.post(
            getAssetActionUrl({
                    dataType: `Account/denyRole`,
                    postfix: userRepoId,
                    actionName: roleEntry
                }),
                {
                    reason,
                    assetRepoId
                }
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: DENY_ROLE, result: response.data})
                    return response
                })
    },
    [REVOKE_ROLE](context, {roleEntry, userRepoId}) {
        return axios.delete(
                getUserAccountUrl('revokeRole') + `/${userRepoId}/${roleEntry}`,
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: REVOKE_ROLE, result: response.data})
                    return response
                })
    },
    [GET_USER_AWARDS](context, {userRepoId}) {
        return axios.get(
                getUserAccountActionUrl(userRepoId, 'awards'),
        )
                .then(async response => {
                    context.commit(
                            GET_ASSET,
                            response.data
                    )
                    context.commit(_PUT_STATUS, {event: GET_USER_AWARDS, result: response.data})
                    return response
                })
    },

    [GET_ACCOUNT_ROLES](context) {
        if (state.roleDebounce && state.roleDebounce > Date.now()) {
            return new Promise(resolve => resolve())
        }
        state.roleDebounce = Date.now() + (1000 * 60 * 20)
        return axios.post(
                getUserAccountUrl("getAccountRoles"),
        )
                .then(async response => {
                    context.commit(GET_ACCOUNT_ROLES, response.data)

                    context.commit(_PUT_STATUS, {event: GET_ACCOUNT_ROLES, result: response.data})
                    return response
                })
                .catch(() => {
                    context.commit(LOGOUT)
                })
    },
}