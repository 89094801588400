<template>
  <div class="landing">
    <LView>
      <template #menubar>

        <DigiQTrackMenuBar
            class="fixed w-full h-20 z-50"
            :class="{
            'bg-white': true,
          }"
        ></DigiQTrackMenuBar>

      </template>

      <template #body>
        <div class="pt-20">
          <router-view></router-view>
        </div>
      </template>
    </LView>
  </div>
</template>

<script>
import {state} from "@/store/store";
import {computed} from "vue";
import LView from "@/components/layout/LView.vue";
import DigiQTrackMenuBar from "@/components/DigiQTrackMenuBar.vue";

export default {
  name: "LandingView",
  components: {
    DigiQTrackMenuBar,
    LView,
  },
  setup() {
    const clientId = computed(() => state.clientId);
    return {
      clientId,
      state,
    };
  },
};
</script>

<style scoped></style>
