<template>
  <svg width="72" height="72" xmlns="http://www.w3.org/2000/svg">
    <g fill-rule="nonzero" fill="none">
      <path
        d="M45 48h-9a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1z"
        fill="#6BCFFF"
      />
      <path
        d="M45 24H27a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1z"
        fill="#FFC760"
      />
      <path
        d="M45 30H27a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V31a1 1 0 0 0-1-1z"
        fill="#2F75FF"
      />
    </g>
  </svg>
</template>
