import {
    _PUT_STATUS,
    CREATE_ASSET,
    DELETE_ASSET,
    GET_ASSET,
    LIST_ASSETS,
    LIST_PRIVATE_ASSETS,
    PUBLISH_ASSET,
    PUT_ASSET,
    PUT_ASSET_ATTRIBUTE,
    PUT_PRIVATE_ASSET,
    RETRACT_ASSET
} from "@/store/operations";
import {getAssetActionUrl, getAssetsUrl, getAssetUrl} from "@/store/lib/apiUrls";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";

export default {
    [LIST_ASSETS](context,
                  {
                      dataType,
                      repoId,
                      permissionHostDataType,
                      permissionHostRepoId,
                      offset,
                      limit,
                      sorting,
                      filter
                  }) {
        const options = {
            dataType,
            permissionHostDataType,
            permissionHostRepoId,
            offset,
            limit,
            sorting,
            filter: JSON.stringify(filter)
        }
        let url = getAssetsUrl({dataType})
        if (repoId) {
            options.repoId = repoId
            url = getAssetActionUrl({dataType, postfix: repoId, actionName: 'find'})
        }
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: options,
        })
            .then(async response => {
                context.commit(
                    LIST_ASSETS,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: LIST_ASSETS, result: response.data})
                return response
            })
    },
    [LIST_PRIVATE_ASSETS](context,
                          {
                              dataType,
                              repoId,
                              permissionHostDataType,
                              permissionHostRepoId,
                              offset,
                              limit,
                              sorting,
                              filter
                          }) {
        const options = {
            dataType,
            permissionHostDataType,
            permissionHostRepoId,
            offset,
            limit,
            sorting,
            filter: JSON.stringify(filter)
        }
        let url = getAssetsUrl({dataType})
        if (repoId) {
            options.repoId = repoId
            url = getAssetActionUrl({dataType, postfix: repoId, actionName: 'private'})
        }
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: options
        })
            .then(async response => {
                context.commit(
                    LIST_ASSETS,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: LIST_PRIVATE_ASSETS, result: response.data})
                return response
            })
    },
    [GET_ASSET](context,
                {
                    repoId,
                    dataType,
                    permissionHostDataType,
                    permissionHostRepoId
                }) {
        return throttleBufferedAction({
            action: axios.post,
            url: getAssetActionUrl({dataType, postfix: repoId, actionName: 'find'}),
            params: {
                permissionHostDataType,
                permissionHostRepoId
            }
        })
            .then(async response => {
                context.commit(
                    GET_ASSET,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: GET_ASSET, result: response.data})
                return response
            })
    },
    [CREATE_ASSET](context, props) {
        const url = getAssetUrl({
            dataType: props.dataType,
            postfix: 'create'
        })
        const options = {
            permissionHostDataType: props.permissionHostDataType,
            permissionHostRepoId: props.permissionHostRepoId,
            attributes: props.attributes,
        }
        if (props.parentId) options.parentId = props.parentId
        return axios.post(
            url,
            options,
        )
            .then(async response => {
                const {Error} = response.data
                if (!Error) {
                    context.commit(
                        GET_ASSET,
                        response.data
                    )
                    return response
                }
                context.commit(GET_ASSET, response.data)
                context.commit(_PUT_STATUS, {event: CREATE_ASSET, result: response.data})
                throw Error
            })
    },
    [PUT_ASSET](context, options) {
        return axios.put(
            getAssetUrl({
                dataType: options.dataType,
                postfix: options.repoId
            }),
            options
        )
            .then(async response => {
                context.commit(
                    GET_ASSET,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: PUT_ASSET, result: response.data})
                return response
            })
    },
    [PUT_PRIVATE_ASSET](context, options) {
        return axios.put(
            getAssetUrl({
                dataType: options.dataType,
                postfix: options.repoId + '/private'
            }),
            options
        )
            .then(async response => {
                context.commit(
                    GET_ASSET,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: PUT_ASSET, result: response.data})
                return response
            })
    },
    [PUBLISH_ASSET](context, asset) {
        return axios.put(
            getAssetActionUrl({
                dataType: asset.dataType,
                postfix: asset.repoId,
                actionName: 'publish'
            }),
            {update: asset}
        )
            .then(async response => {
                context.commit(
                    GET_ASSET,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: PUBLISH_ASSET, result: response.data})
                return response
            })
    },
    [RETRACT_ASSET](context, asset) {
        return axios.put(
            getAssetActionUrl({
                dataType: asset.dataType,
                postfix: asset.repoId,
                actionName: 'retract'
            }),
            {update: asset}
        )
            .then(async response => {
                context.commit(
                    GET_ASSET,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: RETRACT_ASSET, result: response.data})
                return response
            })
    },
    [PUT_ASSET_ATTRIBUTE](context, {id, attributeName, value}) {
        return axios.patch(
            getAssetActionUrl({id}),
            {attributeName, value}
        )
            .then(async response => {
                context.commit(
                    GET_ASSET,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: PUT_ASSET_ATTRIBUTE, result: response.data})
                return response
            })
    },
    [DELETE_ASSET](context, asset) {
        return axios.delete(
            getAssetUrl({
                dataType: asset.dataType,
                postfix: asset.repoId
            })
        )
            .then(async response => {
                context.commit(
                    DELETE_ASSET,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: DELETE_ASSET, result: response.data})
                return response
            })
    },
}
