import {
    _PUT_STATUS,
    CLAIM_TEACHER_ROLE,
    LIST_ASSETS,
    LIST_UNIVERSITY_PEOPLE,
    LIST_UNIVERSITY_REPRESENTATIVES,
    REQUEST_DIGIQT_ACCOUNT,

} from "@/store/operations";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import {getAssetActionUrl, getUserAccountUrl, getAssetUrl} from "@/store/lib/apiUrls";
import axios from "@/plugins/axios";

export default {
    [REQUEST_DIGIQT_ACCOUNT](context, attributes) {
        return throttleBufferedAction({
            action: axios.post,
            url: getUserAccountUrl('requestDigiQTAccount'),
            params: {attributes}
        })
            .then(response => {
                context.commit(_PUT_STATUS, {event: REQUEST_DIGIQT_ACCOUNT, result: response.data})
                return response
            })
    },
  [LIST_UNIVERSITY_PEOPLE](context, universityRepoId) {
    return throttleBufferedAction({
      action: axios.post,
      url: getAssetActionUrl({
        dataType: 'University',
        postfix: universityRepoId,
        actionName: 'listPeople',
      }),
    })
      .then(response => {
        context.commit(LIST_ASSETS, response.data)
        context.commit(_PUT_STATUS, {event: LIST_UNIVERSITY_PEOPLE, result: response.data})
        return response

      })
  },
  [LIST_UNIVERSITY_REPRESENTATIVES](context) {
    return throttleBufferedAction({
      action: axios.get,
      url: getAssetUrl({
        dataType: 'University',
        postfix: 'listRepresentatives',
      }),
    })
      .then(response => {
        context.commit(_PUT_STATUS, {event: LIST_UNIVERSITY_REPRESENTATIVES, result: response.data})
        return response

      })
  },
  [CLAIM_TEACHER_ROLE](context) {
        return throttleBufferedAction({
            action: axios.post,
            url: getUserAccountUrl('claimTeacherRole')
        })
            .then(response => {
                context.commit(LIST_ASSETS, response.data)
                context.commit(_PUT_STATUS, {event: CLAIM_TEACHER_ROLE, result: response.data})
                return response
            })
    }
}