<template>
  <div class="markdown">
    <div class="column">
      <div
          v-if="isEditable"
          class=" flex flex-wrap"
      >
        <div class="w-full p-2 flex justify-between rounded text-zinc-700 bg-zinc-200">
          <div>
            <IconPencil
                class="hover:text-black dark:hover:text-white"
                v-if="!showEditor"
                title="edit"
                @click="shouldBeEditable = true"
            ></IconPencil>
            <IconEye
                class="hover:text-black dark:hover:text-white"
                v-else
                title="view"
                @click="shouldBeEditable = false"
            ></IconEye>
          </div>
          <div class="w-full flex">
            <div class="flex-grow"></div>
            <div
                v-if="showEditor"
                class="buttons flex items-center justify-between"
                style="min-width: 320px; width: 50%; max-width: 440px"
            >
              <FormatHeader1
                  class="hover:text-black dark:hover:text-white"
                  @click="onRangeMutation('h1')"
              ></FormatHeader1>
              <FormatHeader2
                  class="hover:text-black dark:hover:text-white"
                  @click="onRangeMutation('h2')"
              ></FormatHeader2>
              <FormatHeader3
                  class="hover:text-black dark:hover:text-white"
                  @click="onRangeMutation('h3')"
              ></FormatHeader3>
              <FormatBold
                  class="hover:text-black dark:hover:text-white"
                  @click="onRangeMutation('bold')"
              ></FormatBold>
              <FormatItalic
                  class="hover:text-black dark:hover:text-white"
                  @click="onRangeMutation('italic')"
              ></FormatItalic>
              <FormatQuoteOpen
                  class="hover:text-black dark:hover:text-white"
                  @click="onRangeMutation('quote')"
              ></FormatQuoteOpen>
              <FormatListBulleted
                  class="hover:text-black dark:hover:text-white"
                  @click="onRangeMutation('ul')"
              ></FormatListBulleted>
              <FormatListNumbered
                  class="hover:text-black dark:hover:text-white"
                  @click="onRangeMutation('ol')"
              ></FormatListNumbered>
            </div>

          </div>
        </div>

      </div>
      <KeepAlive v-if="showEditor">
      <TextArea
          :value="markdown"
          class="markdown-in looksEditable"
          :tabindex="tabIndex"
          :inputId="inputId"
          :isDirty="isDirty"
          :placeholder="placeholder"
          @delete="onDelete"
          @change="onChange"
          @input="onInput"
          @abort="onAbort"
      ></TextArea>
      </KeepAlive>
      <MarkdownIt
          v-else
          class="markdown-out min-h-[32px]"
          :class="{
              'border border-gray-500': !markdown?.length,
            }"
          :html="true"
          :breaks="true"
          :linkify="true"
          :abbr="true"
          :sub="true"
          :sup="true"
          :mark="true"
          :source="markdown ?? ''"
          @click="onReaderClick"
      ></MarkdownIt>
    </div>
  </div>
</template>

<script>
import MarkdownIt from "vue3-markdown-it";
import {generateRandomString} from "@/lib/typeHelpers/stringFunctions/generateRandomString";
import {computed, ref} from "vue";
import {useEditableState} from "@/composables/useEditableState";
import FormatBold from "../icons/FormatBold";
import FormatHeader1 from "../icons/FormatHeader1";
import FormatHeader2 from "../icons/FormatHeader2";
import FormatHeader3 from "../icons/FormatHeader3";
import FormatItalic from "../icons/FormatItalic";
import FormatListBulleted from "../icons/FormatListBulleted";
import FormatListNumbered from "../icons/FormatListNumbered";
import FormatQuoteOpen from "../icons/FormatQuoteOpen";
import TextArea from "./TextArea";
import IconPencil from "@/components/icons/Pencil.vue";
import IconEye from "@/components/icons/Eye.vue";

export default {
  name: "Markdown",
  emits: ['change', 'input', 'delete'],
  components: {
    FormatBold,
    FormatHeader1,
    FormatHeader2,
    FormatHeader3,
    FormatItalic,
    FormatListBulleted,
    FormatListNumbered,
    FormatQuoteOpen,
    IconEye,
    IconPencil,
    MarkdownIt,
    TextArea,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Write here...'
    },
    tabIndex: {
      type: Number,
      default: -1
    },
    isEditable: {
      type: Boolean,
      default: false
    },
  },
  setup(props, {emit}) {
    const inputId = generateRandomString(12, 'alphabetic')
    const isDirty = ref()
    const shouldBeEditable = ref(props.isEditable)
    const showEditor = computed(() => shouldBeEditable.value && props.isEditable)
    const {mutateEditorSelection} = useEditableState(inputId)
    const originalValue = ref(props.modelValue)
    const markdown = ref(props.modelValue)

    const applySelectionRangeMutation = mutation => {
      const newText = mutateEditorSelection(mutation)
      // sync bit attribute state
      markdown.value = newText
    }

    const onAbort = () => emit('change', originalValue.value)
    const onChange = value => {
      markdown.value = value
      emit('change', value)
    }
    const onInput = value => {
      markdown.value = value
      emit('input', value)
    }
    const onDelete = () => emit('delete')
    const onReaderClick = () => shouldBeEditable.value = !shouldBeEditable.value && props.isEditable
    const onRangeMutation = type => {
      const mutations = {
        h1: {prefix: '\n# '},
        h2: {prefix: '\n## '},
        h3: {prefix: '\n### '},
        h4: {prefix: '\n#### '},
        h5: {prefix: '\n##### '},
        h6: {prefix: '\n###### '},
        bold: {prefix: '**', postfix: '**'},
        italic: {prefix: '_', postfix: '_'},
        quote: {prefix: '> '},
        code: {prefix: '`', postfix: '`'}, // todo
        codeBlock: {prefix: '```\n', postfix: '\n```'},
        strike: {prefix: '~~', postfix: '~~'},
        marker: {prefix: '==', postfix: '=='},
        underline: {prefix: '++', postfix: '++'},
        ul: {prefix: '- '},
        ol: {prefix: '1. '},
        todo: {prefix: '  - [ ] '}, // todo
      }
      applySelectionRangeMutation(mutations[type])
    }

    return {
      inputId,
      isDirty,
      markdown,
      onAbort,
      onChange,
      onInput,
      onDelete,
      onRangeMutation,
      onReaderClick,
      shouldBeEditable,
      showEditor,
    }
  }
}

</script>
