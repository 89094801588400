<script setup>
import LayoutSection from "@/components/mobility/LayoutSection.vue";
import LayoutView from "@/components/mobility/LayoutView.vue";
import usersData from "@/assets/data/helplines.json";
import { computed, ref } from "vue";
import DropdownSingle from "@/components/forms/DropdownSingle.vue";
import LResourceCard from "@/components/resources/LResourceCard.vue";
import markdown from "@/components/forms/Markdown.vue";

const helplineData = ref(usersData);
const selectedOrg = ref(null);
const orgToData = ref({});

helplineData.value.forEach((helpline) => {
  orgToData.value[helpline.ORGANISATION] = helpline;
});

const formatText = (text) => {
  const addedNewLines = text.replaceAll(". ", "\n\n").trimStart();
  return addedNewLines;
};
</script>

<template>
  <div class="networks font-normal text-gray-600">
    <LayoutSection>
      <template #body>
        <div class="flex flex-col mt-20">
          <h1 class="mb-20 font-header">DigiQ Mobility Helpline</h1>
          <p class="font-body text-lg">
            Looking for help? Here are a few general resources provided by the
            institutes.
          </p>
          <dropdown-single
            class="ml-2 mt-2"
            :title="selectedOrg || 'Select Institute'"
            :items="Object.keys(orgToData).sort()"
            @select="(value) => (selectedOrg = value)"
          />
        </div>
        <div v-if="selectedOrg" class="flex flex-wrap">
          <LResourceCard
            v-if="orgToData[selectedOrg]['LOCAL SUPPORT PERSON']"
            class="lResourceCardContent flex flex-col p-4 m-4 w-fit max-w-33"
          >
            <h3 class="">Local Support Person</h3>
            <p class="font-bold text-black">Name:</p>
            <p>{{ orgToData[selectedOrg]["LOCAL SUPPORT PERSON"] }}</p>
            <p class="font-bold text-black">Contact:</p>
            <p>{{ orgToData[selectedOrg]["CONTACT"] }}</p>
          </LResourceCard>
          <LResourceCard
            v-if="orgToData[selectedOrg]['MENTORING / WELCOME BUDDY']"
            class="lResourceCardContent flex flex-col p-4 m-4 w-fit max-w-33"
          >
            <h3 class="">Mentoring / Welcome Buddy</h3>
            <p class="font-bold text-black">See:</p>
            <p>{{ orgToData[selectedOrg]["MENTORING / WELCOME BUDDY"] }}</p>
          </LResourceCard>
          <LResourceCard
            v-if="orgToData[selectedOrg]['ONBOARDING']"
            class="lResourceCardContent flex flex-col p-4 m-4 w-fit max-w-33"
          >
            <h3 class="">Onboarding</h3>
            <p>{{ orgToData[selectedOrg]["ONBOARDING"] }}</p>
          </LResourceCard>
          <LResourceCard
            v-if="orgToData[selectedOrg]['VISA']"
            class="lResourceCardContent flex flex-col p-4 m-4 max-w-33"
          >
            <h3 class="flex">Visa</h3>
            <p class="lResourceCardContent">
              {{ formatText(orgToData[selectedOrg]["VISA"]) }}
            </p>
          </LResourceCard>
          <LResourceCard
            v-if="orgToData[selectedOrg]['HOUSING']"
            class="lResourceCardContent flex flex-col p-4 m-4 w-fit max-w-33"
          >
            <h3 class="">Housing</h3>
            <p>{{ orgToData[selectedOrg]["HOUSING"] }}</p>
          </LResourceCard>
          <LResourceCard
            v-if="orgToData[selectedOrg]['ANONYMOUS HELPLINE']"
            class="lResourceCardContent flex flex-col p-4 m-4 w-fit max-w-33"
          >
            <h3 class="">Anonymous Helpline</h3>
            <p>{{ orgToData[selectedOrg]["ANONYMOUS HELPLINE"] }}</p>
          </LResourceCard>
          <LResourceCard
            v-if="orgToData[selectedOrg]['DISSEMINATION PLATFORM']"
            class="lResourceCardContent flex flex-col p-4 m-4 max-w-33"
          >
            <h3 class="">Dissemination Platform</h3>
            <p class="">
              {{ formatText(orgToData[selectedOrg]["DISSEMINATION PLATFORM"]) }}
            </p>
          </LResourceCard>
        </div>
      </template>
    </LayoutSection>
  </div>
</template>
<style scoped>
h1 {
  @apply text-5xl font-bold text-gray-800
  my-6;
}
h2 {
  @apply text-3xl font-bold text-gray-800
  mb-4;
}
h3 {
  @apply text-2xl font-bold text-gray-800
  mb-4;
  font-family: "Lora", serif;
}
p {
}
.cards > * {
  @apply h-40
  shadow
  p-6;
}
.max-w-33 {
  max-width: 33%;
}
.lResourceCardContent {
  @apply font-body;

  overflow-wrap: anywhere;
}
.logos {
  @apply mt-12
  flex flex-wrap items-center gap-8;
}
.affiliated {
  @apply bg-gray-100 mt-12 p-10;
}
.logos img {
  max-height: 200px;
  max-width: 300px;
}
.textblock {
  @apply h8:columns-2
  k12:columns-3
  mb-20;
}
ul {
  @apply list-disc
  ml-8 mb-2;
}
li {
  @apply list-disc
  ml-9 mb-0.5;
}
.textblock div {
  @apply mb-4;
}
em {
  @apply font-black
  tracking-tight
  not-italic;
}
a {
  @apply text-diversity/80
  hover:text-diversity
  transition-colors
  duration-200;
}

.CostumCard {
  @apply border border-transparent  rounded-3xl overflow-hidden  bg-gradient-to-b from-[#fff] to-[#F4F7FA] shadow-2xl hover:shadow-lg transition-all duration-300 ease-linear;
  background: url("../../../public/images/checkers.png") center / cover
    no-repeat;
}

.active {
  @apply border border-gray-900  shadow-lg hover:shadow-2xl transition-all duration-300 ease-linear z-10;
}
</style>
