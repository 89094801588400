import { createStore } from "vuex";
import actions from "./actions/actions.js";
import mutations from "./mutations.js";
import { reactive } from "vue";

export const state = reactive({
  assets: {},
  status: {},
  acknowledgedErrors: {},
  documentFilter: null,
  queryStates: {},
  UI: {},
  appId: 'digiqt',
  user: null,
  account: null,
});

export default createStore({
  state,
  mutations,
  actions,
});
