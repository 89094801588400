const inProduction = process.env.NODE_ENV !== 'development'
const USING_TLS = inProduction
const PROTOCOL = USING_TLS ? 'https:' : 'http:'
const PORT = process.env.PORT || 3037

const settings = {
  appId: 'digiqt',
  appTitle: 'DigiQ Track',

  apiPath: '/api/v1',
  basePath: '',
  protocol: PROTOCOL,
  appPort: PORT,

  debugLevels: false,
  PRODUCTION_ORIGIN_PATTERN: '^.*$',

  permissionThresholds: {
    'readAsset': 1,
    'maintainAsset': 2,
    'developAsset': 3,
    'manageAsset': 5,
    'readDataType': 41,
    'maintainDataType': 42,
    'developDataType': 43,
    'manageDataType': 45,
    'readApp': 71,
    'maintainApp': 72,
    'developApp': 73,
    'manageApp': 75,
    'devOp': 100,
  },

  roleNames: { // todo: use user.roles instead
    maintainAsset: 'Asset Editor',
    developAsset: 'Asset Developer',
    manageAsset: 'Asset Manager',
    readApp: 'Member',
    manageApp: 'Manager',
    devOp: 'Developer'
  },

  inProduction,
  USING_TLS,
}
module.exports = settings // legacy JS for server import
